<template>
  <div>
    <a-row :gutter="[20, 0]" style="padding: 10px 0px 0">
      <a-col class="mb-30" :span="24">
        <a-checkbox
          v-model="onlinePayment"
          class="f12"
          @change="changeOnlinePayment"
        >
          Pagamento online
        </a-checkbox>
      </a-col>

      <a-col :span="6">
        <a-form-item>
          <label :class="'filled'">Total Cartão de Crédito </label>

          <a-input
            class="travel-input"
            placeholder="Insira o valor"
            v-currency
            @blur="updateCreditCardTotal"
            v-decorator="[
              `payment_methods_credit_card_total_value_${cardID}`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Preencha o valor.',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item>
          <label :class="'filled'">Proprietário do cartão</label>
          <a-select
            class="travel-input select"
            placeholder="Selecione"
            v-decorator="[
              `payment_methods_credit_card_owner_${cardID}`,

              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of CreditCardAndThirdPartyOwners()"
              :key="index"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item>
          <label :class="'filled'">Maquininha</label>
          <a-select
            class="travel-input select"
            placeholder="Selecione"
            allow-clear
            @change="onChangeCardMachine"
            v-decorator="[
              `payment_methods_credit_card_machine_id_${cardID}`,

              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of cardMachines.list"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col
        v-if="
          tempContract[`payment_methods_credit_card_machine_id_${cardID}`] == 14
        "
        :span="6"
      >
        <a-form-item>
          <label :class="'filled'">Fornecedor</label>
          <a-select
            class="travel-input"
            show-search
            :allow-clear="true"
            optionFilterProp="txt"
            placeholder="Selecione "
            v-decorator="[
              `payment_methods_credit_card_machine_supplier_id_${cardID}`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            style="width: 100%; height: 30px"
            @change="onSelectSupplier(cardID)"
          >
            <a-select-option
              v-for="(item, index) of productSupplier.list"
              :key="index"
              :value="item.id"
              :txt="item.trading_name"
            >
              {{ item.id }} -
              {{ item.trading_name.toUpperCase() }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col
        v-if="tempContract[`payment_methods_credit_card_machine_id_${cardID}`]"
        :span="4"
      >
        <a-form-item>
          <label :class="'filled'">Parcelas</label>

          <a-select
            class="travel-input select"
            placeholder="Parcelas"
            @change="calcInstallments"
            v-decorator="[
              `payment_methods_credit_card_installments_${cardID}`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of installments"
              :key="index"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col
        v-if="
          tempContract[`payment_methods_credit_card_installments_${cardID}`]
        "
        :span="6"
      >
        <a-form-item>
          <label :class="'filled'">Valor da 1ª parcela (R$)</label>
          <a-input
            class="travel-input"
            placeholder="Insira o valor"
            v-currency
            v-decorator="[
              `payment_methods_credit_card_first_installment_value_${cardID}`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col
        v-if="
          tempContract[`payment_methods_credit_card_installments_${cardID}`]
        "
        :span="5"
      >
        <a-form-item>
          <label :class="'filled'">Demais parcelas (R$)</label>
          <a-input
            class="travel-input"
            v-currency
            placeholder="Insira o valor"
            v-decorator="[
              `payment_methods_credit_card_installments_value_${cardID}`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Preencha todos números.',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col
        :class="$root.isAdmin() ? '' : 'none'"
        v-if="
          tempContract[`payment_methods_credit_card_installments_${cardID}`] &&
          tempContract[`payment_methods_credit_card_machine_id_${cardID}`] != 14
        "
        :span="5"
      >
        <a-form-item>
          <label :class="'filled'">Taxa PDQ (R$)</label>
          <a-input
            class="travel-input"
            v-currency
            placeholder="Taxa PDQ (R$)"
            :disabled="true"
            v-decorator="[`payment_methods_credit_card_tax_value_${cardID}`]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col
        :class="$root.isAdmin() ? '' : 'none'"
        v-if="
          tempContract[`payment_methods_credit_card_installments_${cardID}`] &&
          tempContract[`payment_methods_credit_card_machine_id_${cardID}`] != 14
        "
        :span="4"
      >
        <a-form-item>
          <label :class="'filled'"> Taxa Adm (R$)</label>
          <a-input
            class="travel-input"
            v-currency
            placeholder="Taxa Adm"
            :disabled="true"
            v-decorator="[
              `payment_methods_credit_card_advance_fee_value_${cardID}`,
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col
        v-if="
          tempContract[`payment_methods_credit_card_installments_${cardID}`] &&
          tempContract[`payment_methods_credit_card_machine_id_${cardID}`] != 14
        "
        :span="4"
      >
        <a-form-item>
          <label :class="'filled'">Total Taxas (R$)</label>
          <a-input
            class="travel-input red"
            v-currency
            placeholder="Total Taxas"
            :disabled="true"
            v-decorator="[
              `payment_methods_credit_card_total_tax_discount_${cardID}`,
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>
    </a-row>

    <aRow
      v-if="
        tempContract[`payment_methods_credit_card_machine_id_${cardID}`] == 14
      "
    >
      <aCol :span="24">
        <HayaRadioField
          label="Tipo de pagamento"
          :fieldKey="`payment_methods_credit_card_payment_type_${cardID}`"
          :required="true"
          description="Informe se o pagamento para o fornecedor foi Total ou Parcial."
          :options="[
            {
              label: 'Parcial',
              value: 'Parcial',
            },
            {
              label: 'Total',
              value: 'Total',
            },
          ]"
          :form:="form"
        />
      </aCol>

      <aCol
        v-if="
          tempContract[`payment_methods_credit_card_payment_type_${cardID}`] ===
          'Parcial'
        "
        :span="24"
      >
        <a-form-item
          class="travel-input travel-select-expandable"
          style="height: auto !important"
        >
          <label :class="'filled'">Localizadores</label>
          <a-select
            class="travellers-selector"
            mode="multiple"
            placeholder="Selecione os localizadores"
            style="width: 100%; height: auto !important"
            v-decorator="[
              `payment_methods_credit_card_travellers_locators_${cardID}`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(locator, index) of locatorsArr"
              :key="index"
              :value="locator"
            >
              {{ locator }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </aCol>
    </aRow>
  </div>
</template>

<script>
import { parse } from "vue-currency-input";

import cardMachinesMixins from "@/mixins/card-machines/cardMachinesMixins.js";
import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";
import HayaRadioField from "@/components/general/fields/HayaRadioField.vue";

export default {
  name: "CreditCardValuesDataCollapse",
  props: {
    contract: Object,
    tempContract: Object,
    form: Object,
    cardID: Number,
  },
  components: { HayaRadioField },
  mixins: [cardMachinesMixins, productSupplierMixins],
  data() {
    return {
      onlinePayment: false,
      cardType: "",
      authCode: "",
      userPassword: null,
      installments: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      locatorsArr: [],
    };
  },
  mounted() {
    this.cardMachines.filters.status.selected = "Ativo";
    this.getCardMachines();

    this.productSupplier.pagination.perPage = 300;
    this.productSupplier.filters.only_specific_categories = 1;
    this.getProductSuppliers();

    const flightRows = this.tempContract[`flight_sections_rows`]
      ? JSON.parse(this.tempContract[`flight_sections_rows`])
      : [];
    const flightTravellers = [];

    if (flightRows.length) {
      flightRows.forEach((flight) => {
        flight.travellers.forEach((traveller) => {
          flightTravellers.push(
            this.tempContract[
              `flight_${flight.id}_traveller_${traveller.id}_locator`
            ]
          );
        });
      });

      const uniqueArray = [...new Set(flightTravellers)];

      this.locatorsArr = uniqueArray;
    }

    setTimeout(() => {
      this.form.setFieldsValue({
        [`payment_methods_credit_card_owner_${this.cardID}`]:
          this.tempContract[
            `payment_methods_credit_card_owner_${this.cardID}`
          ] != undefined
            ? `${
                this.tempContract[
                  `payment_methods_credit_card_owner_${this.cardID}`
                ]
              }`
            : undefined,

        [`payment_methods_credit_card_total_value_${this.cardID}`]:
          this.tempContract[
            `payment_methods_credit_card_total_value_${this.cardID}`
          ],
        [`payment_methods_credit_card_total_with_tax_percentage_discount_${this.cardID}`]:
          this.tempContract[
            `payment_methods_credit_card_total_with_tax_percentage_discount_${this.cardID}`
          ],
        [`payment_methods_credit_card_advance_fee_value_${this.cardID}`]:
          this.tempContract[
            `payment_methods_credit_card_advance_fee_value_${this.cardID}`
          ],
        [`payment_methods_credit_card_total_tax_discount_${this.cardID}`]:
          this.tempContract[
            `payment_methods_credit_card_total_tax_discount_${this.cardID}`
          ],
        [`payment_methods_credit_card_machine_id_${this.cardID}`]:
          this.tempContract[
            `payment_methods_credit_card_machine_id_${this.cardID}`
          ],
        [`payment_methods_credit_card_installments_${this.cardID}`]:
          this.tempContract[
            `payment_methods_credit_card_installments_${this.cardID}`
          ],
        [`payment_methods_credit_card_first_installment_value_${this.cardID}`]:
          this.tempContract[
            `payment_methods_credit_card_first_installment_value_${this.cardID}`
          ],
        [`payment_methods_credit_card_installments_value_${this.cardID}`]:
          this.tempContract[
            `payment_methods_credit_card_installments_value_${this.cardID}`
          ],
        [`payment_methods_credit_card_tax_value_${this.cardID}`]:
          this.tempContract[
            `payment_methods_credit_card_tax_value_${this.cardID}`
          ],
        [`payment_methods_credit_card_payment_type_${this.cardID}`]:
          this.tempContract[
            `payment_methods_credit_card_payment_type_${this.cardID}`
          ],
        [`payment_methods_credit_card_travellers_locators_${this.cardID}`]: this
          .tempContract[
          `payment_methods_credit_card_travellers_locators_${this.cardID}`
        ]
          ? JSON.parse(
              this.tempContract[
                `payment_methods_credit_card_travellers_locators_${this.cardID}`
              ]
            )
          : [],
      });

      this.form.setFieldsValue({
        [`payment_methods_credit_card_machine_supplier_id_${this.cardID}`]:
          this.tempContract[
            `payment_methods_credit_card_machine_supplier_id_${this.cardID}`
          ],
      });

      this.onlinePayment =
        this.tempContract[
          `payment_methods_credit_card_is_payment_online_${this.cardID}`
        ] == 1
          ? true
          : false;
    }, 25);
  },
  methods: {
    changeOnlinePayment(val) {
      this.tempContract[
        `payment_methods_credit_card_is_payment_online_${this.cardID}`
      ] = val.target.checked;
    },
    onSelectSupplier(cardID) {
      setTimeout(() => {
        let supplierId =
          this.tempContract[
            `payment_methods_credit_card_machine_supplier_id_${cardID}`
          ];

        let theSupplier = this.productSupplier.list.filter((supplier) => {
          return supplier.id == supplierId;
        });

        this.tempContract[
          `payment_methods_credit_card_machine_supplier_${cardID}`
        ] = theSupplier[0].trading_name;
      }, 200);
    },
    updateCreditCardTotal(val) {
      if (val != undefined) {
        this.$emit("creditCardTotal", parse(val.target.value));
      } else {
        val = 0;
        this.$emit("creditCardTotal", parse(val));
      }
    },
    calcInstallments(installments) {
      setTimeout(() => {
        let creditCardValue = this.form.getFieldValue(
          `payment_methods_credit_card_total_value_${this.cardID}`
        );

        if (creditCardValue) {
          let monthlyInstallments = [];

          creditCardValue = parse(creditCardValue);

          let taxesRange =
              this.tempContract[
                `payment_methods_credit_card_taxes_range_${this.cardID}`
              ],
            advanceFeeRange =
              this.tempContract[
                `payment_methods_credit_card_advance_fee_range_${this.cardID}`
              ],
            selectedTax = "",
            selectedAdvanceFee = "",
            totalAdvanceFee = 0,
            totalTax = 0;

          if (advanceFeeRange && advanceFeeRange != "[]") {
            advanceFeeRange = JSON.parse(advanceFeeRange);

            if (advanceFeeRange.length > 0) {
              selectedAdvanceFee = advanceFeeRange.filter((e) => {
                return e.id == installments;
              });

              let totalPercentage = 0,
                thePercentage = selectedAdvanceFee[0].value
                  .replace("%", "")
                  .replace(",", ".");
              totalPercentage = parseFloat(thePercentage);

              let theTaxPercentageDiscount = 0;

              theTaxPercentageDiscount =
                (totalPercentage / 100) * creditCardValue;

              this.form.setFieldsValue({
                [`payment_methods_credit_card_advance_fee_percentage_${this.cardID}`]:
                  totalPercentage,
              });

              this.form.setFieldsValue({
                [`payment_methods_credit_card_advance_fee_value_${this.cardID}`]:
                  theTaxPercentageDiscount.toFixed(2).replace(".", ","),
              });

              totalAdvanceFee = theTaxPercentageDiscount;
            }
          } else {
            selectedAdvanceFee = "";
            advanceFeeRange = [];
            totalAdvanceFee = 0;

            this.form.setFieldsValue({
              [`payment_methods_credit_card_advance_fee_percentage_${this.cardID}`]: 0,
            });

            this.form.setFieldsValue({
              [`payment_methods_credit_card_advance_fee_value_${this.cardID}`]: 0,
            });
          }

          if (taxesRange && taxesRange != "[]") {
            taxesRange = JSON.parse(taxesRange);

            if (taxesRange.length > 0) {
              selectedTax = taxesRange.filter((e) => {
                return e.id == installments;
              });

              let totalPercentage = 0,
                thePercentage = selectedTax[0].value
                  .replace("%", "")
                  .replace(",", ".");

              totalPercentage = parseFloat(thePercentage);

              let theTaxPercentageDiscount = 0;

              theTaxPercentageDiscount =
                (totalPercentage / 100) * creditCardValue;

              this.form.setFieldsValue({
                [`payment_methods_credit_card_tax_percentage_${this.cardID}`]:
                  totalPercentage,
              });

              this.form.setFieldsValue({
                [`payment_methods_credit_card_tax_value_${this.cardID}`]:
                  theTaxPercentageDiscount.toFixed(2).replace(".", ","),
              });

              totalTax = theTaxPercentageDiscount;
            }
          } else {
            selectedTax = "";
            taxesRange = [];
            totalTax = 0;

            this.form.setFieldsValue({
              [`payment_methods_credit_card_tax_percentage_${this.cardID}`]: 0,
            });

            this.form.setFieldsValue({
              [`payment_methods_credit_card_tax_value_${this.cardID}`]: 0,
            });
          }

          this.form.setFieldsValue({
            [`payment_methods_credit_card_total_with_tax_percentage_discount_${this.cardID}`]:
              (creditCardValue - (totalTax + totalAdvanceFee))
                .toFixed(2)
                .replace(".", ","),
          });

          this.form.setFieldsValue({
            [`payment_methods_credit_card_total_tax_discount_${this.cardID}`]: (
              totalTax + totalAdvanceFee
            )
              .toFixed(2)
              .replace(".", ","),
          });

          for (let i = 1; i < installments + 1; i++) {
            monthlyInstallments.push(creditCardValue / installments);
          }

          this.form.setFieldsValue({
            [`payment_methods_credit_card_first_installment_value_${this.cardID}`]:
              this.formatPrice(monthlyInstallments[0]),
          });

          this.form.setFieldsValue({
            [`payment_methods_credit_card_installments_value_${this.cardID}`]:
              this.formatPrice(monthlyInstallments[0]),
          });
        }
      }, 50);
    },
    formatPrice(price) {
      return `${Number(parseFloat(price).toFixed(2)).toLocaleString()}`;
    },
    CreditCardAndThirdPartyOwners() {
      let theOwners = [];

      theOwners.push({
        id: -1,
        name: "INFORMAR PROPRIETÁRIO",
        value: "credit-card-owner",
      });

      if (this.tempContract.the_customer.person_type == "Pessoa Física") {
        theOwners.push({
          id: 0,
          name:
            this.tempContract.the_customer.first_name != undefined
              ? "CONTRATANTE: " +
                this.tempContract.the_customer.first_name.toUpperCase() +
                " " +
                this.tempContract.the_customer.last_name.toUpperCase()
              : "",
          value: "client",
        });
      } else {
        theOwners.push({
          id: 0,
          name:
            this.tempContract.the_customer.trading_name != undefined
              ? "CONTRATANTE: " +
                this.tempContract.the_customer.trading_name.toUpperCase()
              : "",
          value: "client",
        });
      }

      return theOwners;
    },
    onChangeCardMachine(id) {
      this.form.setFieldsValue({
        [`payment_methods_credit_card_advance_fee_value_${this.cardID}`]: "",
      });

      this.form.setFieldsValue({
        [`payment_methods_credit_card_tax_value_${this.cardID}`]: "",
      });

      this.form.setFieldsValue({
        [`payment_methods_credit_card_total_tax_discount_${this.cardID}`]: "",
      });

      let tax = this.cardMachines.list.filter((e) => {
        return e.id == id;
      });

      if (tax[0] != undefined) {
        this.form.setFieldsValue({
          [`payment_methods_credit_card_taxes_range_${this.cardID}`]:
            tax[0].taxes_range,
        });

        this.form.setFieldsValue({
          [`payment_methods_credit_card_advance_fee_range_${this.cardID}`]:
            tax[0].advance_fee,
        });
      } else {
        this.form.setFieldsValue({
          [`payment_methods_credit_card_taxes_range_${this.cardID}`]: "[]",
        });

        this.form.setFieldsValue({
          [`payment_methods_credit_card_advance_fee_range_${this.cardID}`]:
            "[]",
        });
        setTimeout(() => {
          this.tempContract[
            `payment_methods_credit_card_tax_id_${this.cardID}`
          ] = "";
        }, 50);
      }
    },
  },
};
</script>
