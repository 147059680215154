<template>
  <div>
    <a-row :gutter="[20, 0]" style="padding: 20px 0px 0">
      <a-col :span="6">
        <a-form-item>
          <label :class="'filled'">Nome completo</label>
          <a-input
            class="travel-input"
            placeholder="Escreva..."
            v-decorator="[
              `payment_methods_credit_card_owner_${cardID}_name`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item>
          <label :class="'filled'">CPF</label>
          <a-input
            class="travel-input"
            placeholder="Escreva..."
            v-mask="'###.###.###-##'"
            v-decorator="[
              `payment_methods_credit_card_owner_${cardID}_CPF`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item>
          <label :class="'filled'">Celular</label>
          <a-input
            class="travel-input"
            placeholder="Escreva..."
            v-mask="'(##) # ####-####'"
            v-decorator="[
              `payment_methods_credit_card_owner_${cardID}_mobile_phone`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item>
          <label :class="'filled'">Telefone fixo</label>
          <a-input
            class="travel-input"
            placeholder="Escreva..."
            v-mask="'(##) ####-####'"
            v-decorator="[`payment_methods_credit_card_owner_${cardID}_phone`]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item>
          <label :class="'filled'">CEP</label>
          <a-input
            class="travel-input"
            placeholder="CEP"
            v-mask="'#####-###'"
            v-decorator="[
              `payment_methods_credit_card_owner_${cardID}_CEP`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            @change="getLocation"
          >
            <a-icon slot="prefix" type="field-svg" />
            <a-icon v-if="cepLoading" slot="suffix" type="loading" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="8">
        <a-form-item>
          <label :class="'filled'">Logradouro</label>
          <a-input
            class="travel-input"
            placeholder="Logradouro"
            v-decorator="[
              `payment_methods_credit_card_owner_${cardID}_location_street`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="4">
        <a-form-item>
          <label :class="'filled'">Número</label>
          <a-input
            type="number"
            class="travel-input"
            placeholder="Número"
            v-decorator="[
              `payment_methods_credit_card_owner_${cardID}_location_number`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item>
          <label :class="'filled'">Complemento</label>
          <a-input
            class="travel-input"
            placeholder="Complemento"
            v-decorator="[
              `payment_methods_credit_card_owner_${cardID}_location_complement`,
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item>
          <label :class="'filled'">Bairro</label>
          <a-input
            class="travel-input"
            placeholder="Bairro"
            v-decorator="[
              `payment_methods_credit_card_owner_${cardID}_location_neighborhood`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item>
          <label :class="'filled'">Cidade</label>
          <a-input
            class="travel-input"
            placeholder="Cidade"
            v-decorator="[
              `payment_methods_credit_card_owner_${cardID}_location_city`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="6">
        <a-form-item>
          <label :class="'filled'">Estado</label>
          <a-select
            class="travel-input"
            show-search
            placeholder="ES"
            v-decorator="[
              `payment_methods_credit_card_owner_${cardID}_location_state`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of states"
              :key="index"
              :value="item.initials"
            >
              {{ item.initials.toUpperCase() }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import states from "@/json/states.json";
import axios from "axios";

export default {
  name: "CreditCardThirdPartyDataCollapse",
  props: {
    contract: Object,
    tempContract: Object,
    form: Object,
    cardID: Number,
  },
  data() {
    return {
      states,
      cepLoading: false,
    };
  },
  mounted() {
    this.setCreditCardOwnerFields();
  },
  methods: {
    setCreditCardOwnerFields() {
      this.form.setFieldsValue({
        [`payment_methods_credit_card_owner_${this.cardID}_name`]: this
          .tempContract[
          `payment_methods_credit_card_owner_${this.cardID}_name`
        ],
        [`payment_methods_credit_card_owner_${this.cardID}_CPF`]: this
          .tempContract[`payment_methods_credit_card_owner_${this.cardID}_CPF`],
        [`payment_methods_credit_card_owner_${this.cardID}_mobile_phone`]: this
          .tempContract[
          `payment_methods_credit_card_owner_${this.cardID}_mobile_phone`
        ],
        [`payment_methods_credit_card_owner_${this.cardID}_phone`]: this
          .tempContract[
          `payment_methods_credit_card_owner_${this.cardID}_phone`
        ],
        [`payment_methods_credit_card_owner_${this.cardID}_CEP`]: this
          .tempContract[`payment_methods_credit_card_owner_${this.cardID}_CEP`],
        [`payment_methods_credit_card_owner_${this.cardID}_location_street`]: this
          .tempContract[
          `payment_methods_credit_card_owner_${this.cardID}_location_street`
        ],
        [`payment_methods_credit_card_owner_${this.cardID}_location_number`]: this
          .tempContract[
          `payment_methods_credit_card_owner_${this.cardID}_location_number`
        ],
        [`payment_methods_credit_card_owner_${this.cardID}_location_complement`]: this
          .tempContract[
          `payment_methods_credit_card_owner_${this.cardID}_location_complement`
        ],
        [`payment_methods_credit_card_owner_${this.cardID}_location_neighborhood`]: this
          .tempContract[
          `payment_methods_credit_card_owner_${this.cardID}_location_neighborhood`
        ],
        [`payment_methods_credit_card_owner_${this.cardID}_location_city`]: this
          .tempContract[
          `payment_methods_credit_card_owner_${this.cardID}_location_city`
        ],
        [`payment_methods_credit_card_owner_${this.cardID}_location_state`]: this
          .tempContract[
          `payment_methods_credit_card_owner_${this.cardID}_location_state`
        ],
      });
    },
    getLocation() {
      setTimeout(() => {
        if (
          this.form.getFieldValue(
            `payment_methods_credit_card_owner_${this.cardID}_CEP`
          )
        ) {
          if (
            this.form
              .getFieldValue(
                `payment_methods_credit_card_owner_${this.cardID}_CEP`
              )
              .replace("-", "").length == 8
          ) {
            this.cepLoading = true;
            axios
              .get(
                "https://viacep.com.br/ws/" +
                  this.form.getFieldValue(
                    `payment_methods_credit_card_owner_${this.cardID}_CEP`
                  ) +
                  "/json/"
              )
              .then(({ data }) => {
                this.cepLoading = false;
                this.form.setFieldsValue({
                  [`payment_methods_credit_card_owner_${this.cardID}_location_street`]: data.logradouro.toUpperCase(),
                });

                this.form.setFieldsValue({
                  [`payment_methods_credit_card_owner_${this.cardID}_location_state`]: data.uf,
                });

                this.form.setFieldsValue({
                  [`payment_methods_credit_card_owner_${this.cardID}_location_city`]: data.localidade.toUpperCase(),
                });

                this.form.setFieldsValue({
                  [`payment_methods_credit_card_owner_${this.cardID}_location_neighborhood`]: data.bairro.toUpperCase(),
                });
              })
              .catch(({ response }) => {
                response;
                this.$message.error(
                  "Houve um problema ao recuperar a localização."
                );
                this.cepLoading = false;
              });
          }
        }
      }, 110);
    },
  },
};
</script>