<template>
  <div>
    <aRow :gutter="[20, 0]">
      <aCol :span="24">
        <div class="owner-card">
          <div class="head" @click="onClickOpenCollapse('values')">
            <aRow :gutter="0">
              <aCol class="id cprimary" :span="1">
                <span class="ico">
                  <img
                    src="@/assets/images/dashboard/contracts/card-payment.png"
                    width="25"
                    alt="card-payment"
                  />
                </span>
              </aCol>

              <aCol class="id-name" :span="23"> VALORES / TAXAS </aCol>
            </aRow>
          </div>

          <div class="content" v-if="openCollapse === 'values'">
            <CreditCardValuesDataCollapse
              :contract="contract"
              :tempContract="tempContract"
              :form="form"
              :cardID="cardID"
              @creditCardTotal="updateCreditCardTotal"
            />
          </div>
        </div>
      </aCol>
    </aRow>

    <aRow
      v-if="
        tempContract[`payment_methods_credit_card_owner_${cardID}`] ===
        'credit-card-owner'
      "
      :gutter="[20, 0]"
    >
      <aCol :span="24">
        <div class="owner-card">
          <div class="head" @click="onClickOpenCollapse('third-party')">
            <aRow :gutter="0">
              <aCol class="id cprimary" :span="1">
                <span class="ico">
                  <img
                    src="@/assets/images/dashboard/contracts/third-party.png"
                    width="20"
                    alt="third-party"
                  />
                </span>
              </aCol>

              <aCol class="id-name" :span="23">
                PROPRIETÁRIO:
                {{
                  tempContract[
                    `payment_methods_credit_card_owner_${cardID}_name`
                  ]
                    ? tempContract[
                        `payment_methods_credit_card_owner_${cardID}_name`
                      ]
                    : `NOME`
                }}
              </aCol>
            </aRow>
          </div>

          <div class="content" v-if="openCollapse === 'third-party'">
            <CreditCardThirdPartyDataCollapse
              :contract="contract"
              :tempContract="tempContract"
              :form="form"
              :cardID="cardID"
            />
          </div>
        </div>
      </aCol>
    </aRow>

    <aRow :gutter="[20, 0]">
      <aCol :span="24">
        <div class="owner-card">
          <div class="head" @click="onClickOpenCollapse('cardData')">
            <aRow :gutter="0">
              <aCol class="id cprimary" :span="1">
                <span class="ico">
                  <img
                    src="@/assets/images/dashboard/contracts/credit-card.png"
                    width="20"
                    style="opacity: 0.7"
                    alt="credit-card"
                  />
                </span>
              </aCol>

              <aCol class="id-name" :span="23"> DADOS DO CARTÃO </aCol>
            </aRow>
          </div>

          <div class="content" v-if="openCollapse === 'cardData'">
            <CreditCardDataCollapse
              :contract="contract"
              :tempContract="tempContract"
              :form="form"
              :travellers="travellers"
              :cardID="cardID"
            />
          </div>
        </div>
      </aCol>
    </aRow>
  </div>
</template>

<script>
import CreditCardDataCollapse from "@/components/contracts/collapses/CreditCardDataCollapse.vue";
import CreditCardValuesDataCollapse from "@/components/contracts/collapses/CreditCardValuesDataCollapse.vue";
import CreditCardThirdPartyDataCollapse from "@/components/contracts/collapses/CreditCardThirdPartyDataCollapse.vue";

export default {
  name: "CreditCardCollapse",
  props: {
    contract: Object,
    tempContract: Object,
    form: Object,
    cardID: Number,
    reservation_total_price: Number,
    travellers: Array,
  },
  components: {
    CreditCardDataCollapse,
    CreditCardValuesDataCollapse,
    CreditCardThirdPartyDataCollapse,
  },
  data() {
    return {
      openCollapse: "values",
    };
  },
  mounted() {
    setTimeout(() => {
      this.updateCreditCardTotal(
        this.tempContract[
          `payment_methods_credit_card_total_value_${this.cardID}`
        ]
      );
    }, 50);
  },
  methods: {
    onClickOpenCollapse(id) {
      if (id == this.openCollapse) {
        this.openCollapse = 0;
      } else {
        this.openCollapse = id;
      }
    },
    updateCreditCardTotal(val) {
      if (val != undefined) {
        this.$emit("creditCardTotal", val);
      } else {
        val = 0;
        this.$emit("creditCardTotal", val);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.owner-card
  margin-bottom: 20px
  .content
    padding: 10px 20px 20px !important
    border: 1px solid #ccc
    margin-top: -14px
    border-radius: 0 0 6px 6px
    margin-bottom: 11px
    background: #fff
  .head
    cursor: pointer
    clear: both
    padding:  10px 20px
    background: #fff
    border: 1px solid #ccc
    margin-bottom: 10px
    border-radius: 6px
    .id
      color: #474747
      font-weight: 600
      .ico
        position: relative
        filter: gray
        -webkit-filter: grayscale(1)
        -webkit-filter: grayscale(100%)
      .num
        position: absolute
        right: -13px
        top: 0
        font-size: 11px
        color: #333
    .id-name
      color: #42413f
      font-weight: 500
      text-transform: uppercase
</style>
