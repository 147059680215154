<template>
  <div>
    <aRow
      v-if="showCreditCardFields"
      :gutter="[20, 0]"
      style="padding: 20px 10px 0"
    >
      <aRow :gutter="[20, 0]">
        <aCol :span="24">
          <aRow :gutter="[20, 0]">
            <aCol :span="10">
              <a-form-item>
                <label for :class="'filled'">Nome impresso no cartão</label>
                <a-input
                  class="travel-input"
                  placeholder="Escreva..."
                  v-decorator="[
                    `payment_methods_credit_card_client_name_${cardID}`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Preencha todos números.',
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </aCol>

            <aCol :span="8">
              <a-form-item>
                <label for :class="'filled'">Numero do cartão</label>
                <a-input
                  class="travel-input"
                  placeholder="Digite os números"
                  v-mask="'#### #### #### ####'"
                  @blur="setCardType"
                  v-decorator="[
                    `payment_methods_credit_card_client_number_${cardID}`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Preencha todos números.',
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </aCol>

            <aCol :span="6">
              <a-form-item class="travel-input-outer readonly">
                <label for :class="'filled'">Bandeira</label>
                <a-input
                  placeholder="Bandeira"
                  v-decorator="[
                    `payment_methods_credit_card_client_flag_${cardID}`,
                  ]"
                >
                </a-input>
              </a-form-item>
            </aCol>

            <aCol :span="4">
              <a-form-item>
                <label for :class="'filled'">Mês de Expiração</label>
                <a-select
                  class="travel-input select"
                  placeholder="Selecione"
                  v-decorator="[
                    `payment_methods_credit_card_client_month_${cardID}`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Preencha todos números.',
                        },
                      ],
                    },
                  ]"
                >
                  <a-select-option
                    v-for="(item, index) of cardMonths"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </aCol>

            <aCol :span="4">
              <a-form-item>
                <label for :class="'filled'">Ano de Expiração</label>
                <a-select
                  class="travel-input select"
                  placeholder="Selecione"
                  v-decorator="[
                    `payment_methods_credit_card_client_year_${cardID}`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Preencha todos números.',
                        },
                      ],
                    },
                  ]"
                >
                  <a-select-option
                    v-for="(item, index) of cardYears"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </aCol>

            <aCol :span="5">
              <a-checkbox
                class="mt-10 f12 relative"
                v-model="haveCvv"
                @change="onChangeHaveCvv"
              >
                Cliente tem CVV?
              </a-checkbox>
            </aCol>

            <aCol :span="4" v-if="haveCvv">
              <a-form-item
                v-if="showCreditCardFields"
                :class="
                  tempContract['status'] == 'concluded' && !$root.isAdmin()
                    ? 'none'
                    : ''
                "
              >
                <label
                  for
                  :class="
                    tempContract[
                      `payment_methods_credit_card_client_cvv_${cardID}`
                    ]
                      ? 'filled'
                      : ''
                  "
                  >CVV</label
                >
                <a-input
                  type="number"
                  class="travel-input"
                  placeholder="CVV"
                  v-mask="'####'"
                  v-decorator="[
                    `payment_methods_credit_card_client_cvv_${cardID}`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Preencha todos números.',
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </aCol>

            <aCol :span="24" v-if="!haveCvv">
              <a-form-item class="textarea">
                <label for :class="'filled'">Motivo de não ter CVV</label>
                <a-input
                  class="travel-textarea"
                  placeholder="Escreva..."
                  type="textarea"
                  v-decorator="[
                    `payment_methods_credit_card_obs_${cardID}`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                </a-input>
              </a-form-item>
            </aCol>
          </aRow>
        </aCol>
        <aCol class="mt-20" :span="24">
          <a-form-item
            class="travel-input travel-select-expandable"
            style="height: auto !important"
          >
            <label for :class="'filled'"
              >Viajantes relacionados a este cartão</label
            >
            <a-select
              class="travellers-selector"
              mode="multiple"
              placeholder="Selecione os viajantes desta autorização"
              style="width: 100%; height: auto !important"
              v-decorator="[
                `payment_methods_credit_card_travellers_${cardID}`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-select-option
                v-for="(traveller, index) of travellers"
                :key="index"
                :value="traveller.id"
              >
                {{ traveller.name }}
                {{ traveller.lastName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </aCol>
      </aRow>
    </aRow>

    <aRow
      class="credit-card-data"
      v-if="!showCreditCardFields"
      :gutter="[20, 0]"
    >
      <aCol :span="24">
        <aRow :gutter="[20, 0]">
          <aCol class="card-col" :span="5">
            <label for>Nome impresso no cartão</label>
            <div class="value dotted-phrase">
              {{
                contract.meta[
                  `payment_methods_credit_card_masked_name_${cardID}`
                ]
                  ? contract.meta[
                      `payment_methods_credit_card_masked_name_${cardID}`
                    ]
                  : "xxxxxxxxxxxxx"
              }}
            </div>
          </aCol>
          <aCol class="card-col" :span="6">
            <label for>Numero do cartão</label>
            <div class="value">
              {{
                contract.meta[
                  `payment_methods_credit_card_masked_number_${cardID}`
                ]
                  ? contract.meta[
                      `payment_methods_credit_card_masked_number_${cardID}`
                    ]
                  : "xxxx xxxx xxxx xxxx"
              }}
            </div>
          </aCol>
          <aCol class="card-col" :span="4">
            <label for>Expiração do cartão</label>
            <div class="value">
              {{
                contract.meta[
                  `payment_methods_credit_card_masked_month_${cardID}`
                ]
                  ? contract.meta[
                      `payment_methods_credit_card_masked_month_${cardID}`
                    ]
                  : "xx/xxxx"
              }}
              {{
                contract.meta[
                  `payment_methods_credit_card_masked_year_${cardID}`
                ]
                  ? "/" +
                    contract.meta[
                      `payment_methods_credit_card_masked_year_${cardID}`
                    ]
                  : ""
              }}
            </div>
          </aCol>
          <aCol class="card-col" :span="4">
            <label for>Bandeira</label>
            <div class="value">
              {{
                contract.meta[
                  `payment_methods_credit_card_masked_flag_${cardID}`
                ]
                  ? contract.meta[
                      `payment_methods_credit_card_masked_flag_${cardID}`
                    ]
                  : "xxxxxxx"
              }}
            </div>
          </aCol>
          <aCol class="card-col" :span="3">
            <label for>CVV</label>
            <div class="value">
              {{
                contract.meta[
                  `payment_methods_credit_card_masked_cvv_${cardID}`
                ]
                  ? contract.meta[
                      `payment_methods_credit_card_masked_cvv_${cardID}`
                    ]
                  : "xxx"
              }}
            </div>
          </aCol>
          <aCol class="card-col view" :span="2">
            <a-tooltip placement="top" title="Ver dados do cartão">
              <a-button
                type="primary"
                @click="showPasswordModal = true"
                shape="circle"
                icon="eye"
              />
            </a-tooltip>

            <aModal
              :visible="showPasswordModal"
              :footer="false"
              :width="300"
              @cancel="showPasswordModal = false"
            >
              <template #title> <aIcon type="lock" /> Verificação </template>
              <LoginFormModal
                v-if="showPasswordModal"
                :updateStorageData="false"
                @verificationSuccessfully="verificationSuccessfully"
              />
            </aModal>
          </aCol>
        </aRow>
      </aCol>

      <aCol class="mt-20" :span="24">
        <a-form-item
          class="travel-input travel-select-expandable"
          style="height: auto !important"
        >
          <label for :class="'filled'"
            >Viajantes relacionados a este cartão</label
          >
          <a-select
            class="travellers-selector"
            mode="multiple"
            placeholder="Selecione os viajantes desta autorização"
            style="width: 100%; height: auto !important"
            v-decorator="[
              `payment_methods_credit_card_travellers_${cardID}`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(traveller, index) of travellers"
              :key="index"
              :value="traveller.id"
            >
              {{ traveller.name }}
              {{ traveller.lastName }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </aCol>
    </aRow>
  </div>
</template>

<script>
import LoginFormModal from "../../login/LoginFormModal.vue";
export default {
  name: "CreditCardDataCollapse",
  props: {
    contract: Object,
    tempContract: Object,
    form: Object,
    cardID: Number,
    travellers: Array,
  },
  components: { LoginFormModal },
  data() {
    return {
      userPassword: "",
      authCode: "",
      showCreditCardFields: true,
      showPasswordModal: false,
      haveCvv: false,
      getCardDataLoading: false,
      cardMonths: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      cardYears: [
        "2021",
        "2022",
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
        "2029",
        "2030",
        "2031",
        "2032",
        "2033",
        "2034",
        "2035",
        "2036",
        "2037",
        "2038",
        "2039",
        "2040",
      ],
    };
  },
  mounted() {
    this.showCreditCardFields =
      this.tempContract[
        `payment_methods_credit_card_masked_name_${this.cardID}`
      ] == undefined &&
      this.tempContract[
        `payment_methods_credit_card_masked_number_${this.cardID}`
      ] == undefined &&
      this.tempContract[
        `payment_methods_credit_card_masked_month_${this.cardID}`
      ] == undefined &&
      this.tempContract[
        `payment_methods_credit_card_masked_year_${this.cardID}`
      ] == undefined &&
      this.tempContract[
        `payment_methods_credit_card_masked_cvv_${this.cardID}`
      ] == undefined &&
      this.tempContract[
        `payment_methods_credit_card_masked_flag_${this.cardID}`
      ] == undefined
        ? true
        : false;

    setTimeout(() => {
      this.setCreditCardFields();
      this.setCreditCardMaskedFields();
    }, 20);
  },
  methods: {
    setCreditCardMaskedFields() {
      this.form.setFieldsValue({
        [`payment_methods_credit_card_masked_name_${this.cardID}`]:
          this.tempContract[
            `payment_methods_credit_card_masked_name_${this.cardID}`
          ],
        [`payment_methods_credit_card_masked_number_${this.cardID}`]:
          this.tempContract[
            `payment_methods_credit_card_masked_number_${this.cardID}`
          ],
        [`payment_methods_credit_card_travellers_${this.cardID}`]: this
          .tempContract[`payment_methods_credit_card_travellers_${this.cardID}`]
          ? JSON.parse(
              this.tempContract[
                `payment_methods_credit_card_travellers_${this.cardID}`
              ]
            )
          : [],
        [`payment_methods_credit_card_masked_flag_${this.cardID}`]:
          this.tempContract[
            `payment_methods_credit_card_masked_flag_${this.cardID}`
          ],

        [`payment_methods_credit_card_masked_month_${this.cardID}`]:
          this.tempContract[
            `payment_methods_credit_card_masked_month_${this.cardID}`
          ],
        [`payment_methods_credit_card_masked_year_${this.cardID}`]:
          this.tempContract[
            `payment_methods_credit_card_masked_year_${this.cardID}`
          ],
        [`payment_methods_credit_card_masked_cvv_${this.cardID}`]:
          this.tempContract[
            `payment_methods_credit_card_masked_cvv_${this.cardID}`
          ],

        [`payment_methods_credit_card_obs_${this.cardID}`]:
          this.tempContract[`payment_methods_credit_card_obs_${this.cardID}`],
        [`payment_methods_credit_card_have_cvv_${this.cardID}`]:
          this.checkCreditCardCVV(
            this.tempContract[
              `payment_methods_credit_card_have_cvv_${this.cardID}`
            ]
          ),
      });
    },
    setCreditCardFields() {
      this.form.setFieldsValue({
        [`payment_methods_credit_card_client_name_${this.cardID}`]:
          this.tempContract[
            `payment_methods_credit_card_client_name_${this.cardID}`
          ],
        [`payment_methods_credit_card_client_number_${this.cardID}`]:
          this.tempContract[
            `payment_methods_credit_card_client_number_${this.cardID}`
          ],

        [`payment_methods_credit_card_client_flag_${this.cardID}`]:
          this.tempContract[
            `payment_methods_credit_card_client_flag_${this.cardID}`
          ],

        [`payment_methods_credit_card_client_month_${this.cardID}`]:
          this.tempContract[
            `payment_methods_credit_card_client_month_${this.cardID}`
          ],
        [`payment_methods_credit_card_client_year_${this.cardID}`]:
          this.tempContract[
            `payment_methods_credit_card_client_year_${this.cardID}`
          ],
        [`payment_methods_credit_card_client_cvv_${this.cardID}`]:
          this.tempContract[
            `payment_methods_credit_card_client_cvv_${this.cardID}`
          ],

        [`payment_methods_credit_card_client_obs_${this.cardID}`]:
          this.tempContract[
            `payment_methods_credit_card_client_obs_${this.cardID}`
          ],
      });

      this.haveCvv = this.checkCreditCardCVV(
        this.tempContract[`payment_methods_credit_card_have_cvv_${this.cardID}`]
      );
    },
    checkCreditCardCVV(haveCvv) {
      let flag = false;

      if (haveCvv == true || haveCvv == 1) flag = true;

      return flag;
    },
    setCardType() {
      setTimeout(() => {
        this.form.setFieldsValue({
          [`payment_methods_credit_card_client_flag_${this.cardID}`]:
            this.GetCardType(),
        });
      }, 5);
    },
    GetCardType() {
      let number = this.form.getFieldValue(
        `payment_methods_credit_card_client_number_${this.cardID}`
      );

      if (number) {
        let theFlag = "";

        number = number.replace(/\s+/g, "");

        // visa
        var re = new RegExp("^4");
        if (number.match(re) != null) {
          theFlag = "Visa";
        }

        // Mastercard
        if (
          /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
            number
          )
        ) {
          theFlag = "Mastercard";
        }

        // AMEX
        re = new RegExp("^3[47]");
        if (number.match(re) != null) {
          theFlag = "AMEX";
        }

        // Discover
        re = new RegExp(
          "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
        );
        if (number.match(re) != null) {
          theFlag = "Discover";
        }

        // Diners
        re = new RegExp("^36");
        if (number.match(re) != null) {
          theFlag = "Diners";
        }

        // Diners - Carte Blanche
        re = new RegExp("^30[0-5]");
        if (number.match(re) != null) {
          theFlag = "Diners - Carte Blanche";
        }

        // JCB
        re = new RegExp("^35(2[89]|[3-8][0-9])");
        if (number.match(re) != null) {
          theFlag = "JCB";
        }

        // Visa Electron
        re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
        if (number.match(re) != null) {
          theFlag = "Visa Electron";
        }

        // Hipercard
        re = new RegExp("^606282|^637095|^637599|^637568");
        if (number.match(re) != null) {
          theFlag = "Hipercard";
        }

        // Elo
        re = new RegExp(
          "^(4011(78|79)|43(1274|8935)|45(1416|7393|763(1|2))|50(4175|6699|67[0-7][0-9]|9000)|50(9[0-9][0-9][0-9])|627780|63(6297|6368)|650(03([^4])|04([0-9])|05(0|1)|05([7-9])|06([0-9])|07([0-9])|08([0-9])|4([0-3][0-9]|8[5-9]|9[0-9])|5([0-9][0-9]|3[0-8])|9([0-6][0-9]|7[0-8])|7([0-2][0-9])|541|700|720|727|901)|65165([2-9])|6516([6-7][0-9])|65500([0-9])|6550([0-5][0-9])|655021|65505([6-7])|6516([8-9][0-9])|65170([0-4]))"
        );
        if (number.match(re) != null) {
          theFlag = "Elo";
        }

        return theFlag;
      }

      return "";
    },
    onChangeHaveCvv(val) {
      this.tempContract[`payment_methods_credit_card_have_cvv_${this.cardID}`] =
        val.target.checked;
    },
    verificationSuccessfully(verificationData) {
      this.showPasswordModal = false;
      this.getCardDataLoading = true;

      this.$hayaApi
        .post(`/contracts/credit-cards-data`, {
          ...verificationData,
          contractId: this.contract.id,
        })
        .then(({ data }) => {
          this.$message.success(data.message);
          this.showCreditCardFields = data.show_fields;

          setTimeout(() => {
            this.form.setFieldsValue({
              [`payment_methods_credit_card_client_name_${this.cardID}`]: data
                .card[`payment_methods_credit_card_client_name_${this.cardID}`]
                ? data.card[
                    `payment_methods_credit_card_client_name_${this.cardID}`
                  ]
                : this.tempContract[
                    `payment_methods_credit_card_client_name_${this.cardID}`
                  ],
            });

            this.form.setFieldsValue({
              [`payment_methods_credit_card_client_number_${this.cardID}`]: data
                .card[
                `payment_methods_credit_card_client_number_${this.cardID}`
              ]
                ? data.card[
                    `payment_methods_credit_card_client_number_${this.cardID}`
                  ]
                : this.tempContract[
                    `payment_methods_credit_card_client_number_${this.cardID}`
                  ],
            });

            this.form.setFieldsValue({
              [`payment_methods_credit_card_client_flag_${this.cardID}`]: data
                .card[`payment_methods_credit_card_client_flag_${this.cardID}`]
                ? data.card[
                    `payment_methods_credit_card_client_flag_${this.cardID}`
                  ]
                : this.tempContract[
                    `payment_methods_credit_card_client_flag_${this.cardID}`
                  ],
            });

            this.form.setFieldsValue({
              [`payment_methods_credit_card_client_month_${this.cardID}`]: data
                .card[`payment_methods_credit_card_client_month_${this.cardID}`]
                ? data.card[
                    `payment_methods_credit_card_client_month_${this.cardID}`
                  ]
                : this.tempContract[
                    `payment_methods_credit_card_client_month_${this.cardID}`
                  ],
            });

            this.form.setFieldsValue({
              [`payment_methods_credit_card_client_year_${this.cardID}`]: data
                .card[`payment_methods_credit_card_client_year_${this.cardID}`]
                ? data.card[
                    `payment_methods_credit_card_client_year_${this.cardID}`
                  ]
                : this.tempContract[
                    `payment_methods_credit_card_client_year_${this.cardID}`
                  ],
            });

            if (
              data.card[`payment_methods_credit_card_client_cvv_${this.cardID}`]
            ) {
              this.haveCvv = true;
              this.form.setFieldsValue({
                [`payment_methods_credit_card_have_cvv_${this.cardID}`]: true,
              });
            } else {
              this.haveCvv = false;
              this.form.setFieldsValue({
                [`payment_methods_credit_card_have_cvv_${this.cardID}`]: false,
              });
            }

            this.form.setFieldsValue({
              [`payment_methods_credit_card_client_cvv_${this.cardID}`]: data
                .card[`payment_methods_credit_card_client_cvv_${this.cardID}`]
                ? data.card[
                    `payment_methods_credit_card_client_cvv_${this.cardID}`
                  ]
                : this.tempContract[
                    `payment_methods_credit_card_client_cvv_${this.cardID}`
                  ],
            });

            this.form.setFieldsValue({
              [`payment_methods_credit_card_obs_${this.cardID}`]:
                this.tempContract[
                  `payment_methods_credit_card_obs_${this.cardID}`
                ],
            });
          }, 500);
        })
        .finally(() => {
          this.getCardDataLoading = false;
        });
    },
  },
};
</script>
